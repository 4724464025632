<template>
  <div class="container">
    <el-form :model="timeForm"
             :rules="timeRules"
             ref="timeForm">
      <el-form-item class="width300"
                    prop="started_at">
        <el-date-picker v-model="timeForm.dateTime"
                        type="datetimerange"
                        value-format="yyyy-MM-dd HH:mm:ss"
                        range-separator="至"
                        @change="dateChange"
                        :default-time="['00:00:00', '23:59:59']"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期">
        </el-date-picker>
      </el-form-item>
      <el-form-item class="mt-15">
        <el-radio-group v-model="timeForm.time_rule"
                        @change="change">
          <el-radio label="ANY">有效期内，任意时段进行</el-radio>
          <el-radio label="REGULAR">有效期内，规则日期进行</el-radio>
          <el-radio label="IRREGULAR">有效期内，不规则日期进行</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-row v-if="timeForm.time_rule === 'REGULAR'">
        <el-form-item prop="timeForm"
                      :rules="{
                        validator: regularTimeCheck, trigger: 'change'
                      }">
          <el-checkbox-group v-model="timeForm.time_rule_week"
                             @change="change">
            <el-checkbox :label="1">周一</el-checkbox>
            <el-checkbox :label="2">周二</el-checkbox>
            <el-checkbox :label="3">周三</el-checkbox>
            <el-checkbox :label="4">周四</el-checkbox>
            <el-checkbox :label="5">周五</el-checkbox>
            <el-checkbox :label="6">周六</el-checkbox>
            <el-checkbox :label="7">周日</el-checkbox>
          </el-checkbox-group>
          <el-row v-for="(item,index) in timeForm.time_rule_time"
                  style="margin-top:20px"
                  :key="'a'+index">
            <el-time-picker is-range
                            v-model="timeForm.time_rule_time[index]"
                            range-separator="至"
                            format="HH:mm"
                            :clearable="false"
                            value-format="HH:mm"
                            start-placeholder="开始时间"
                            end-placeholder="结束时间"
                            placeholder="选择时间范围">
            </el-time-picker>
            <el-button type="text"
                       class="ml-15"
                       v-if="index === timeForm.time_rule_time.length-1 && index !== 0"
                       @click="delRuleTime(index)">删除</el-button>
            <el-button type="text"
                       class="ml-15"
                       v-if="timeForm.time_rule_time.length<3 && index === timeForm.time_rule_time.length-1 "
                       @click="addRuleTime()">添加</el-button>
          </el-row>
          <div class="input-tip mt-15">
            请按照24小时制输入可用时段，最多设置3个时段
          </div>
        </el-form-item>

      </el-row>
      <template v-if="timeForm.time_rule === 'IRREGULAR'">
        <el-form-item prop="time_rule_day"
                      :rules="{
                        validator: irRegularDayCheck, trigger: 'change'
                      }">
          <el-row v-for="(item,index) in timeForm.time_rule_day"
                  class="mt-15"
                  :key="index">
            <el-date-picker v-model="timeForm.time_rule_day[index]"
                            type="daterange"
                            value-format="yyyy-MM-dd"
                            range-separator="至"
                            start-placeholder="开始日期"
                            end-placeholder="结束日期">
            </el-date-picker>
            <el-button type="text"
                       class="ml-15"
                       v-if="index === timeForm.time_rule_day.length-1 && index !== 0"
                       @click="delRuleDay(index)">删除</el-button>
            <el-button type="text"
                       class="ml-15"
                       v-if="index === timeForm.time_rule_day.length-1 "
                       @click="addRuleDay()">添加</el-button>
          </el-row>
        </el-form-item>
        <el-form-item prop="time_rule_time"
                      :rules="{
                        validator: irRegularTimeCheck, trigger: 'change'
                      }">
          <el-row v-for="(item,index) in timeForm.time_rule_time"
                  style="margin-top:20px"
                  :key="'a'+index">
            <el-time-picker is-range
                            v-model="timeForm.time_rule_time[index]"
                            range-separator="至"
                            format="HH:mm"
                            :clearable="false"
                            value-format="HH:mm"
                            start-placeholder="开始时间"
                            end-placeholder="结束时间"
                            placeholder="选择时间范围">
            </el-time-picker>
            <el-button type="text"
                       class="ml-15"
                       v-if="index === timeForm.time_rule_time.length-1 && index !== 0"
                       @click="delRuleTime(index)">删除</el-button>
            <el-button type="text"
                       class="ml-15"
                       v-if="timeForm.time_rule_time.length<3 && index === timeForm.time_rule_time.length-1 "
                       @click="addRuleTime()">添加</el-button>
          </el-row>
          <div class="input-tip mt-15">
            请按照24小时制输入可用时段，最多设置3个时段
          </div>
        </el-form-item>

      </template>
    </el-form>
  </div>
</template>

<script>
export default {
  props: ['dateRule'],
  data () {
    const timeRuleWeekCheck = (rule, value, callback) => {
      if (this.timeForm.time_rule === 'REGULAR') {
        if (value.length > 0) {
          callback()
        } else {
          return callback(new Error('请勾选可用时间'))
        }
      } else {
        callback()
      }
    }
    return {
      time_rule: 'ANY',
      started_at: '',
      ended_at: '',
      dateTime: [],
      ruleDay: [],
      time_rule_week: [1, 2, 3, 4, 5, 6, 7],
      ruleTime: ['00:00', '23:59'],
      time_rule_time: [],
      time_rule_day: [],
      timeForm: {
        time_rule: 'ANY',
        started_at: '',
        ended_at: '',
        dateTime: [],
        ruleDay: [],
        time_rule_week: [1, 2, 3, 4, 5, 6, 7],
        time_rule_time: [['00:00', '23:59']],
        time_rule_day: [['', '']]
      },
      timeRules: {
        started_at: [
          { required: true, message: '选择开始结束时间', trigger: 'change' }
        ],
        time_rule_week: [
          { validator: timeRuleWeekCheck, trigger: 'change' }
        ]
      }
    }
  },
  watch: {
    dateRule (newVal) {
      const val = Object.assign({}, newVal)
      this.timeForm.time_rule = val.time_rule
      this.timeForm.dateTime = [val.started_at || '', val.ended_at || '']
      this.timeForm.started_at = val.started_at
      this.timeForm.ended_at = val.ended_at
      this.timeForm.time_rule_week = val.time_rule_week
      this.timeForm.time_rule_time = val.time_rule_time
      this.timeForm.time_rule_day = val.time_rule_day.length > 0 ? val.time_rule_day : [['', '']]
    }
  },

  components: {
  },
  methods: {
    validate () {
      let flag = null
      this.$refs.timeForm.validate(valid => {
        if (valid) {
          flag = true
        } else {
          flag = false
        }
      })
      return flag
    },
    checkIntersection (allArr, arr) {
      for (let k = 0; k < allArr.length; k++) {
        if (allArr[k][1] > arr[0] && allArr[k][0] < arr[1]) {
          return true
        }
      }
    },
    checkRegularTime (arr) {
      if (arr.length > 1) {
        const allArr = []
        allArr.push(arr[0])
        const checkArr = [...arr]
        checkArr.splice(0, 1)
        for (let i = 0; i < checkArr.length; i++) {
          if (this.checkIntersection(allArr, checkArr[i])) {
            return true
          } else {
            allArr.push(checkArr[i])
          }
        }
        return false
      } else {
        return false
      }
    },
    regularTimeCheck (rule, value, callback) {
      if (this.timeForm.time_rule_week.length > 0) {
        if (!this.checkDayInTimeWeek(this.timeForm.time_rule_week, this.timeForm.dateTime)) {
          callback(new Error('实际无可用时间，请检查时间设置'))
          return
        }
        for (const time of this.timeForm.time_rule_time) {
          if (time[0] === time[1]) {
            callback(new Error('开放时间不能相等'))
            return
          }
        }
        if (this.checkRegularTime(this.timeForm.time_rule_time)) {
          callback(new Error('可用开放时间不能重合'))
        } else {
          callback()
        }
      } else {
        callback(callback(new Error('请勾选开放时间')))
      }
    },
    dateChange (e) {
      if (e) {
        this.timeForm.dateTime = [e[0], e[1]]
        this.timeForm.started_at = e[0]
        this.timeForm.ended_at = e[1]
      } else {
        this.timeForm.started_at = ''
        this.timeForm.ended_at = ''
      }
      this.change()
    },
    addRuleTime () {
      this.timeForm.time_rule_time.push(['00:00', '23:59'])
      this.change()
    },
    addRuleDay () {
      this.timeForm.time_rule_day.push(['', ''])
      this.change()
    },
    delRuleTime (index) {
      this.timeForm.time_rule_time.splice(index, 1)
      this.validate()
      this.change()
    },
    delRuleDay (index) {
      this.timeForm.time_rule_day.splice(index, 1)
      this.validate()
      this.change()
    },
    change () {
      this.$emit('input', {
        started_at: this.timeForm.started_at,
        ended_at: this.timeForm.ended_at,
        time_rule: this.timeForm.time_rule,
        time_rule_week: this.timeForm.time_rule_week,
        time_rule_time: this.timeForm.time_rule_time,
        time_rule_day: this.timeForm.time_rule_day
      })
      this.$emit('change', {
        started_at: this.timeForm.started_at,
        ended_at: this.timeForm.ended_at,
        time_rule: this.timeForm.time_rule,
        time_rule_week: this.timeForm.time_rule_week,
        time_rule_time: this.timeForm.time_rule_time,
        time_rule_day: this.timeForm.time_rule_day
      })
    },
    getWeekList (start, end) {
      const weekList = []
      const endDate = new Date(end.replace(/-/g, '/'))
      const startDate = new Date(start.replace(/-/g, '/'))
      while (endDate.getTime() - startDate.getTime() >= 0) {
        if (weekList.length > 6) {
          break
        }
        let day = startDate.getDay()
        if (day === 0) { day = 7 }
        weekList.push(day)
        startDate.setDate(startDate.getDate() + 1)
      }
      return weekList
    },
    checkDayInTimeWeek (week, date) {
      const list = this.getWeekList(...date)
      for (let i = 0; i < week.length; i++) {
        for (let j = 0; j < list.length; j++) {
          if (week[i] === list[j]) {
            return true
          }
        }
      }
      return false
    },
    irRegularDayCheck (rule, value, callback) {
      if (this.checkIrRegularDayInTime()) {
        callback(new Error('开始时间或结束时间要在有效期范围内'))
      } else if (this.checkRegularTime(this.timeForm.time_rule_day)) {
        callback(new Error('时间范围不能重合或者交叉'))
      } else {
        callback()
      }
    },
    // 检查时间范围是否包含在开始结束范围内
    checkIrRegularDayInTime () {
      for (const time of this.timeForm.time_rule_day) {
        if (time[0] < this.timeForm.started_at.substring(0, 10) || time[1] > this.timeForm.ended_at.substring(0, 10)) {
          return true
        }
      }
    },
    // 检查是否不规则时间范围有重合
    irRegularTimeCheck (rule, value, callback) {
      if (this.checkRegularTime(this.timeForm.time_rule_time)) {
        callback(new Error('可用开放时间不能重合'))
      } else {
        callback()
      }
    }

  },
  mounted () {
  }
}
</script>

<style lang="scss" scoped>
</style>
